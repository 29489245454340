/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useIsCheckingAuth } from '../../hooks/useAuth';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useBrand, useDefaultBrand, useSite } from '../../contexts/SiteContext';
import SubscriptionForm from '../forms/SubscriptionForm';
import PromotionDescription from '../typography/PromotionDescription';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/pages/subscribe-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    disabled: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    brandSlug: null,
    disabled: false,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
};
function SubscribePage({
    brandSlug,
    disabled,
    current,
    entered,
    entering,
    leaving,
    className,
    contentClassName,
}) {
    const url = useUrlGenerator();
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null, label: brandLabel } = brand || {};
    const { addMessage } = usePopupsContext();
    const { subscriptions } = useSite();
    const subscription = subscriptions.find(
        ({ handle: subscriptionHandle }) => subscriptionHandle === brandHandle,
    );
    const isCheckingAuth = useIsCheckingAuth();
    const user = useUser();
    const { subscriptions: userSubscriptions = [] } = user || {};
    const { description } = subscription;
    const [subscribed, setSubscribed] = useState(false);
    // const [full, setFull] = useState(true);
    // const onFocus = useCallback(() => {
    //     setFull(true);
    // }, [setFull]);
    // const onCancel = useCallback(() => {
    //     setFull(false);
    // }, [setFull]);
    const onComplete = useCallback(() => {
        setSubscribed(true);
        addMessage('subscribe', {
            subscription,
        });
    }, [setSubscribed, addMessage, subscription]);

    useEffect(() => {
        if (!isCheckingAuth && current) {
            const initialSubscribed =
                userSubscriptions.findIndex(
                    ({ id, subscribed: userSubscribed = false }) =>
                        id === subscription.handle && userSubscribed,
                ) !== -1;
            setSubscribed(initialSubscribed);
        }
    }, [isCheckingAuth, current]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
            data-brand={brandHandle}
        >
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <PromotionTitle className={styles.title}>
                        {subscribed ? (
                            <FormattedMessage
                                defaultMessage="Vous êtes abonné·e à {brand}!"
                                values={{
                                    brand: brandLabel,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Abonnez-vous à {brand}!"
                                values={{
                                    brand: brandLabel,
                                }}
                            />
                        )}
                    </PromotionTitle>
                    <PromotionDescription className={styles.description} html={description} />
                    <div className={styles.form}>
                        {!isCheckingAuth || user !== null ? (
                            <SubscriptionForm
                                subscription={brandHandle}
                                full={subscribed}
                                source="page"
                                withoutButtons
                                onComplete={onComplete}
                                fieldsClassName={styles.fields}
                                subscriptionsClassName={styles.subscriptions}
                            />
                        ) : (
                            <div className={styles.placeholder}>Chargement...</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

SubscribePage.propTypes = propTypes;
SubscribePage.defaultProps = defaultProps;

export default SubscribePage;
