/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AccountIcon from '../icons/AccountIcon';

import styles from '../../styles/fields/user-field.module.css';

const propTypes = {
    withBorder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    withBorder: false,
    className: null,
};

function UserField({ withBorder, className }) {
    const { email, phone } = useUser();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
        >
            <AccountIcon circleColor="transparent" className={styles.icon} loggedIn />
            <span className={styles.label}>{email || phone}</span>
        </div>
    );
}

UserField.propTypes = propTypes;
UserField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <UserField {...props} inputRef={ref} />);
